* {
  padding: 0;
  margin: 0;
}

.invoice_detail {
  background-color: #fff;
  padding: 60px 40px 20px;
  position: relative;
  overflow: hidden;
}

.invoice_detail:after{
  position: absolute;
    content: "";
    height: 200px;
    width: 200px;
    background-color: rgb(16 152 118 / 43%);
    top: -45px;
    left: -30px;
    border-radius: 50%;
}

.invoice_detail::before{
  position: absolute;
    content: "";
    width: 300px;
    height: 300px;
    background-color: rgb(16 152 118 / 43%);
    left: -50px;
    top: -84px;
    border-radius: 50%;
}

.color-primary {
  color: var(--primary);
}
.color-secondary {
  color: var(--secondary);
}

.cambg-primary {
  background-color: var(--primary, #224b8b) !important;
}
.cambg-secondary {
  background-color: var(--secondary, #224b8b) !important;
}

.css-13cymwt-control {
  border-width: 0px;
  border-radius: 10px !important;
  box-shadow: 0px 0px 5px #d9d9d9 !important;
}
input#react-select-2-input {
  padding: 4px !important;
}

input#react-select-3-input {
  padding: 4px !important;
}
.css-13cymwt-control {
  border-width: 0px !important;
}

input.rmdp-input {
  padding: 21px !important;
  border: none;
}

.renter_profile_drop {
  background-color: #5f9ea087;
  outline: none;
  border: 0px;
  color: #fff;
}

.rmdp-container {
  display: inline-block;
  height: max-content;
  /* display: inline-block; */
  width: 100%;
  /* padding: 10px; */
  /* border: none; */
  box-shadow: 0px 0px 5px #d9d9d9;
  border-radius: 10px;
}

.navbard_profile_box {
  border: 2px solid royalblue;
  padding: 3px;
  border-radius: 10px;
  box-shadow: 0px 0.5px 2px 1px;
}

.react-autosuggest__suggestions-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.searchrowbox .react-autosuggest__suggestion {
  margin-bottom: 0;
  padding-bottom: 0;
}
.react-autosuggest__suggestions-container--open {
  background-color: #fff;
  border: 1px solid #aaa;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: block;
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 300;
  position: absolute;
  top: 39px;
  width: auto;
  z-index: 2;
}

.phoneInput {
  display: inline-block;
  width: 100% !important;
  padding: 20px 49px;
  border: none;
  box-shadow: 0px 0px 5px #d9d9d9;
  border-radius: 10px;
}

.react-autosuggest__suggestions-list li:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
.searchrowbox .react-autosuggest__suggestion {
  margin-bottom: 0;
  padding-bottom: 0;
}
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}
/* .react-autosuggest__suggestions-list li {
  border: 1px solid silver;
  padding: 3px;
  margin: 3px;
  border-radius: 4px;
} */

/* [class*="sidebar-dark-"] {
  background-color: #6cc7a2;
} */
html,
body {
  height: 100%;
}
#root {
  height: 100%;
}

.bg-dark {
  background: #224b8b !important;
}

.adminColor {
  background: #224b8b !important;
}

.adminBtnColor {
  background-color: #224b8b !important;
  color: #fff;
}
.adminBtnColor:hover {
  color: #fff;
}

.renterCart {
  position: relative;
  top: 40px;
  right: 72px;
}

.sidebar-img .brand_img_side_bar img {
  width: 117px;
  margin-left: 9px;
}

.card-info:not(.card-outline) > .card-header {
  /* background-color: #5dbac8; */
}

.btn-info {
  color: #fff;
  background-color: #5dbac8;
  border-color: #5dbac8;
  box-shadow: none;
}

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active {
  background-color: #007bff;
  color: #fff;
}

[class*="sidebar-dark-"] .sidebar a {
  color: #fff;
}

.brand-link .brand-image {
  float: left;
  line-height: 0.8;
  /* margin-left: 0.8rem; */
  margin-right: 0.5rem;
  margin-top: -3px;
  max-height: 35px !important;
  width: auto;
}

.brand_img_side_bar {
  text-align: center;
  display: block;
}

.brand_img_side_bar img {
  width: 185px;
  height: auto;
  box-shadow: 0px 0px 20px rgba(21, 21, 21, 0.15);
  padding: 10px;
}

.renterProfile {
  text-align: center;
  display: block;
}

.renterProfile img {
  width: 185px;
  height: 185px;
  box-shadow: 0px 0px 20px rgba(21, 21, 21, 0.15);
}

.renterProfile .defaultImage {
  width: 185px;
  height: auto;
  box-shadow: 0px 0px 20px rgba(21, 21, 21, 0.15);
}

.renterProfile .toggle {
  position: relative;
  background: #fff;
  width: 34px;
  height: 34px;
  top: 129px;
  left: 153px;
  font-size: 20px;
  vertical-align: middle;
  box-shadow: 0px 0px 10px rgba(21, 21, 21, 0.15);
  cursor: pointer;
  border-radius: 20px;
  -webkit-user-select: none;
  user-select: none;
  transition: box-shadow 300ms ease;
}

.sold_condo_btn {
  display: flex;
  gap: 20px;
  padding: 0 20px 20px 20px;
}

.card-primary:not(.card-outline) > .card-header {
  /* background-color: #5dbac8; */
}

/* .sold_condo_btn button,
.card-footer button {
  background-color: #5dbac8 ;
  border-color: #5dbac8 ;
} */

.card-header {
  /* background-color: #5dbac8; */
  color: #fff;
}

[class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link {
  color: #fff;
}

.cam_pass_creation_div {
  display: flex;
  gap: 15px;
}

.cam_pass_creation_div .form-check .form-check-input {
  margin-top: 0.4rem;
}

.point_of_contact {
  font-size: 18px;
  font-weight: 800;
}

.card-footer .dalete_btn {
  background-color: red !important;
  border: red !important;
  color: #fff;
}

.add_new_vendor_listing {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add_new_vendor_listing .btn,
.add_new_vendor_listing .btn:active {
  background-color: #6cc7a2;
  border: 1px solid #fff;
}
.sidebar .accordion-item {
  background-color: transparent;
  border: none;
}
.sidebar .accordion-body {
  padding: 0;
}
.sidebar .accordion-button {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: none;
  color: #fff;
  padding: 12px 20px;
  border-radius: 5px !important;
}
.sidebar .accordion-button.collapsed {
  background-color: transparent;
}

.sidebar .accordion-body .nav-item {
  margin-top: 5px;
}

.sidebar .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
.sidebar .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

/* Login page css */
.loginformpage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.loginform {
  width: 450px;
  border-radius: 5px;
  box-shadow: 0 9px 50px hsla(20, 67%, 75%, 0.31);
  padding: 50px;
  background-image: linear-gradient(-225deg, #5dbac8 50%, #a3dcc5 50%);
}
/* form Container */

/* the header form form */
.loginheader {
  margin: 0 0 30px;
  text-align: center;
}
.loginheader img {
  margin: 0 0 30px;
  height: 50px;
}
/* Login title form form */
.loginheader h2 {
  font-size: 25px;
  font-weight: bold;
  margin: 0 0 10px;
  color: #fff;
}
.loginheader p {
  letter-spacing: 0.05em;
  color: #fff;
}
/* .sidebar .accordion-button.collapsed p{display: none;} */
.inputicon {
  background: #fff;
  color: #333;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  font-size: 14px;
  border-radius: 5px 0 0 5px;
}

.loginform .form-control {
  padding-left: 50px;
  border: none;
}

.loading_icon_full {
  text-align: center;
  width: 100%;
  height: 100%;
  z-index: 1050;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(255 255 255 / 70%);
}
.loading_icon_full img {
  height: 50px;
}
.loading_icon_full p {
  margin: 10px 0 0;
  font-weight: bold;
}
.loading_icon {
  text-align: center;
}

.loading_icon img {
  height: 50px;
}

.loading_icon p {
  margin: 10px 0 0;
  font-weight: bold;
}

.user-profile-pic {
  width: 200px;
  max-height: 200px;
  display: inline-block;
}

.user-file-upload {
  display: none;
}
.user-circle {
  border-radius: 100% !important;
  overflow: hidden;
  width: 128px;
  height: 128px;
  border: 7px solid rgb(255 255 255);

  top: 72px;
}
.user-img {
  max-width: 100%;
  height: auto;
}

.defaultImage {
  max-width: 100%;
  height: auto;
}

.user-p-image {
  position: relative;
  top: -40px;
  right: -56px;
  color: #666666;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.user-p-image:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.imagebox label {
  position: relative;
  margin: 0;
  cursor: pointer;
}
.imagebox img {
  height: 160px;
  width: auto;
  border-radius: 10px;
}
.floatbtn {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  background: #fff;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 50%);
}

.renter_header {
  background-color: #e1819c;
}

.guest_section {
  background-color: rgb(44 188 217 / 34%);
}

.guest_label {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 5px;
}

.form_input_guest {
  padding-bottom: 15px;
}

input[type="date"],
select {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 2.8em;
  min-width: 95%;
  background-color: #fff;
}

.form_input_guest input[type="email"],
.form_input_guest input[type="password"],
.form_input_guest input[type="text"],
.form_input_guest input[type="date"],
.form_input_guest select,
.form_input_guest input[type="number"] {
  display: inline-block;
  width: 100%;
  padding: 10px;
  border: none;
  box-shadow: 0px 0px 5px #d9d9d9;
  border-radius: 10px;
}

.form_btn button {
  display: block;
  border: none;
  padding: 10px 25px;
  border-radius: 10px;
  font-weight: 600;
  margin-top: 15px;
  background-color: #000;
  color: #fff;
}

.input_form_submit_btn button {
  display: inline-block;
  width: 100%;
  padding: 12px 0;
  border-radius: 10px;
  border: none;
  font-weight: 600;
  margin-top: 7px;
}

.guest_brand_logo {
  transition: all 0.3s;
}

.guest_brand_logo:hover {
  transform: scale(1.05);
  transition: all 0.3s;
}

.discount_section {
  background-color: #e1819c;
}

.guest_footer {
  background-color: #000;
}

.main_passtype {
  display: flex;
}
.bannersection {
  position: relative;
}
.new_guest_form {
  padding: 30px;
  border-radius: 10px;
  background: rgb(255 255 255 / 80%);
}
@media (max-width: 767px) {
  .logo {
    text-align: center;
  }

  .form_btn button {
    margin: 10px 0;
  }

  .main_passtype {
    display: block;
  }

  .invoice_detail {
    padding: 25px 20px 20px;
}

.invoice_detail:after{
    height: 150px;
    width: 150px;
}


.invoice_detail::before{
  width: 220px;
  height: 220px;
  left: -65px;
}
}

@media (min-width: 768px) and (max-width: 991px) {
  .form_btn button {
    padding: 10px 15px;
  }
}

@media (max-width: 1300px) {
  .cam_pass_creation_div {
    flex-wrap: wrap;
  }
}

.parking_card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card_heading,
.car_detail {
  flex-grow: 1;
}

.wrapper{
  overflow-x: hidden;
}